import { combineReducers } from "redux"
import customizer from "./customizer/"
import tabs from "./tabs/"
import auth from "./auth/"
import navbar from "./navbar/Index"
import products from "./products/"
import generaldata from "./generaldata"

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  products: products,
  generaldata: generaldata,
  tabs: tabs
})

export default rootReducer
