import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  {
    id: "home",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/",
    is_admin: false
  },
  {
    id: "doctors",
    title: "Practitioners",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/doctors",
    // disabled: true
    is_admin: false
  },
  {
    id: "settings",
    title: "Settings",
    type: "item",
    icon: <Icon.Settings size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/page2",
    // disabled: true
    is_admin: true
  },
  {
    id: "agent",
    title: "Agents",
    type: "item",
    icon: <Icon.UserCheck size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/agents",
    is_admin: true
    // disabled: true
  },
  {
    id: "summary",
    title: "Reports",
    type: "item",
    icon: <Icon.BarChart2 size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/summary",
    is_admin: true
    // disabled: true
  }
  
]

export default navigationConfig