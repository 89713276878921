var temp = {
  loggedInUser : {
    "is_admin" : false
  }
}


export const login = (state = { userRole: "admin", is_logged_in:false, values: temp }, action) => {
  switch (action.type) {
    
    case "LOGIN_WITH_JWT": {
      return { ...state, values: action.payload, is_logged_in: action.payload.is_logged_in }
    }
    case "LOGOUT_WITH_JWT": {
      return { ...state, values: action.payload, is_logged_in: false }
    }
    case "CHANGE_ROLE": {
      return { ...state, userRole: action.userRole }
    }
    default: {
      return state
    }
  }
}
